<template>
	<div class="mt-20 md:mt-0">
		<div
			class="flex items-center justify-between mb-4 bg-verdedark w-full text-white font-bold fixed md:static"
		>
			<span class="ml-2"
				>Editando inventario de
				<router-link
					:to="{
						name: 'ProveedorCuenta',
						params: {
							id: this.$route.params.id,
							nombre: this.$route.params.nombre,
							user: this.$route.params.user
						}
					}"
					>{{ this.$route.params.nombre.replaceAll("_", " ") }}</router-link
				></span
			>
			<div class="flex justify-center">
				<router-link
					class="font-bold py-2 px-4 inline-flex items-center focus:outline-none"
					to="/AgregarProducto"
				>
					<svg
						id="Capa_1"
						enable-background="new 0 0 551.13 551.13"
						height="512"
						viewBox="0 0 551.13 551.13"
						width="512"
						class="h-6 w-6 mr-2 fill-current text-white"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="m275.565 0c-151.944 0-275.565 123.621-275.565 275.565s123.621 275.565 275.565 275.565 275.565-123.621 275.565-275.565-123.621-275.565-275.565-275.565zm0 516.685c-132.955 0-241.119-108.164-241.119-241.119s108.164-241.12 241.119-241.12 241.12 108.164 241.12 241.119-108.165 241.12-241.12 241.12z"
						/>
						<path
							d="m292.788 137.783h-34.446v120.56h-120.56v34.446h120.56v120.56h34.446v-120.56h120.56v-34.446h-120.56z"
						/>
					</svg>
					<span class="underline"> Añadir Producto </span>
				</router-link>
			</div>
		</div>
		<div>
			<div class="flex justify-between">
				<div class="flex justify-between ml-20">
					<div class="flex items-center">
						<span>Tipo:</span>
						<div
							class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in mx-2"
						>
							<input
								type="checkbox"
								name="toggle"
								id="toggle"
								v-model="pivotUSDBoolean"
								:checked="pivotUSDBoolean"
								class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border appearance-none cursor-pointer focus:outline-none"
							/>
							<label
								for="toggle"
								class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
							></label>
						</div>
						<div>
							<p v-if="pivotUSDBoolean">Ingreso precios USD</p>
							<p v-else>Ingreso precios Bs</p>
						</div>
					</div>
				</div>
				<div
					class="w-68 mb-8 mt-4 flex justify-center border-azul border-2 rounded-lg mr-20"
				>
					<div class="my-5 flex justify-center">
						<p v-if="localId[0].tasa_dia > 0" class="font-bold mr-5">
							Tasa Proveedor(Bs):
						</p>
						<p v-else class="bg-yellow-300 font-bold mr-5">
							Tasa Shoopers(Bs):
						</p>
						<input
							class="border-azul border-2 rounded-md w-32"
							type="text"
							@change="tasaCambioFormato"
							v-model.lazy="tasaCambioProveedorInput"
						/>
					</div>
				</div>
			</div>

			<div>
				<div class="flex justify-center mt-20 md:mt-0">
					<table class="table-auto mx-6 ml-68 md:ml-0">
						<thead>
							<tr>
								<th class="border-2">Rubro</th>
								<th class="border-2">Producto</th>
								<th class="border-2">Unidad</th>
								<th class="border-2">Precio Bs</th>
								<th class="border-2">Precio $</th>
								<th class="border-2">IVA</th>
								<th class="border-2">% Descuento</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="border-2">
									<vSelect
										class="w-40"
										:options="llenarRubro"
										v-model="rubroNuevo"
									></vSelect>
								</td>
								<td class="border-2">
									<vSelect
										class="w-48"
										:options="llenarProducto[rubroNuevo]"
										v-model="productoSelect"
										:key="selectRefresh"
									></vSelect>
								</td>
								<td class="border-2">
									<select name="unidad" id="" v-model="seleccionUnidad">
										<option value="kg" selected>Kg</option>
										<option value="cesta">Cesta</option>
										<option value="manojo">Manojo</option>
										<option value="unid">Unid</option>
										<option value="saco">Saco</option>
									</select>
								</td>
								<td v-if="pivotUSDBoolean" class="border-2">
									{{ precioBsInput }}
								</td>
								<td v-else class="border-2">
									<input
										class="w-full"
										type="text"
										@change="cambioPrecioUSD_BS"
										v-model.lazy="precioBsInput"
										value="Ingresar Precio Bs"
									/>
								</td>
								<td v-if="pivotUSDBoolean" class="border-2">
									<input
										class="w-full"
										type="text"
										@change="cambioPrecioUSD_BS"
										v-model.lazy="precioUsdInput"
										value="Ingresar Precio USD"
									/>
								</td>
								<td v-else class="border-2">
									{{ precioUsdInput }}
								</td>
								<td class="border-2 w-10">
									<input class="w-full" type="text" v-model="iva" value="iva" />
								</td>
								<td class="border-2 w-28">
									<input
										class="w-full"
										type="text"
										v-model="localId[0].descuento_general"
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div class="flex justify-between mt-10 mx-20">
					<button
						class="border border-primaB rounded-lg bg-prima hover:bg-orange-300 text-black font-bold px-2 py-1 sm:py-2 sm:px-4 inline-flex items-center focus:outline-none"
						type="submit"
						@click="preciosCero()"
					>
						Llevar Precios a Cero
					</button>

					<button
						class="border border-primaB rounded-lg bg-prima hover:bg-orange-300 text-black font-bold px-2 py-1 sm:py-2 sm:px-4 inline-flex items-center focus:outline-none"
						type="submit"
						@click="agregarProducto"
					>
						Agregar Producto
					</button>

					<button
						v-if="mostrarBotonGuardar"
						class="border border-primaB rounded-lg bg-prima hover:bg-orange-300 text-black font-bold px-2 py-1 sm:py-2 sm:px-4 inline-flex items-center focus:outline-none ml-10"
						type="submit"
						@click="guardarCambiosProducto"
					>
						Guardar Cambios
					</button>
					<button
						v-else
						class="border border-primaB rounded-lg bg-prima hover:bg-orange-300 text-black font-bold px-2 py-1 sm:py-2 sm:px-4 inline-flex items-center focus:outline-none ml-10"
						type="submit"
					>
						Guardar Cambios
					</button>
				</div>
				<div v-if="showMessage" class="flex justify-center">
					<p class="py-10">{{ mensaje }}</p>
				</div>
			</div>
			<div
				v-if="deleteMessage"
				class="fixed top-0 left-0 z-50 w-screen h-screen flex items-center justify-center"
			>
				<div
					class="bg-azul w-68 h-40 z-40 absolute rounded-lg shadow-2xl mt-32"
				>
					<div class="flex justify-center py-6">
						<p class="font-bold text-lg text-white">
							¿Confirmar para Eliminar Producto?
						</p>
					</div>
					<div class="flex justify-around">
						<button
							class="border-primaB rounded-lg bg-prima hover:bg-orange-300 text-black font-bold pl-56 py-1 sm:py-2 sm:px-4 inline-flex items-center focus:outline-none"
							@click="borrarProducto"
						>
							Eliminar
						</button>
						<button
							class="border-primaB rounded-lg bg-prima hover:bg-orange-300 text-black font-bold px-2 py-1 sm:py-2 sm:px-4 inline-flex items-center focus:outline-none"
							@click="deleteMessage = false"
						>
							Cancelar
						</button>
					</div>
				</div>
			</div>
			<div class="my-10 ml-32 md:mx-10">
				<div class="flex items-center justify-center ml-72 md:ml-0">
					<table class="table-auto">
						<thead>
							<tr>
								<th></th>
								<th class="border-2 px-4 py-2">Rubro</th>
								<th class="border-2 px-4 py-2">ID</th>
								<th class="border-2 px-4 py-2">Producto</th>
								<th class="border-2 px-4 py-2">Disponibilidad</th>
								<th class="border-2 px-4 py-2">Unidad</th>
								<th class="border-2 px-4 py-2">Precio Bs</th>
								<th class="border-2 px-4 py-2">Precio $</th>
								<th class="border-2 px-4 py-2">IVA</th>
								<th class="border-2 px-4 py-2">% Descuento</th>
								<th class="border-2 px-4 py-2">Fecha actualización</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="k in localId[0].precio.length" :key="k">
								<td scope="row">
									<button
										@click="borrarFilaIndex(localId[0].precio.length - k)"
									>
										<svg
											@click="deleteMessage = true"
											class="hover:opacity-100 cursor-pointer fill-current text-red-500 opacity-75"
											width="30"
											height="30"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<g id="delete_sweep_24px">
												<path
													id="icon/content/delete_sweep_24px"
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M6 4H10L11 5H14V7H2V5H5L6 4ZM5 20C3.89999 20 3 19.1 3 18V8H13V18C13 19.1 12.1 20 11 20H5ZM22 8H15V10H22V8ZM19 16H15V18H19V16ZM15 12H21V14H15V12ZM5 10H11V18H5V10Z"
												/>
											</g>
										</svg>
									</button>
								</td>
								<td class="border-2 px-4 py-2">
									{{
										firstLetterUpperCase(
											localId[0].precio[localId[0].precio.length - k].producto
												.rubro
										)
									}}
								</td>
								<td class="border-2 px-4 py-2">
									{{ localId[0].precio[localId[0].precio.length - k].id }}
								</td>
								<td class="border-2 px-4 py-2">
									{{
										firstLetterUpperCase(
											localId[0].precio[localId[0].precio.length - k].producto
												.producto
										)
									}}
								</td>
								<td class="border-2 px-4 py-2">
									<select
										name="estado"
										v-model="
											localId[0].precio[localId[0].precio.length - k].existencia
										"
										class="px-1 ml-2 focus:outline-none bg-white"
										:class="
											localId[0].precio[localId[0].precio.length - k]
												.existencia === '1' ||
											localId[0].precio[localId[0].precio.length - k]
												.existencia === 1
												? 'text-green-500'
												: 'text-red-500'
										"
									>
										<option value="1">Disponible</option>
										<option value="0">No disponible</option>
									</select>
								</td>
								<td class="border-2 px-4 py-2">
									<select
										name="unidad"
										v-model="
											localId[0].precio[localId[0].precio.length - k].unidad
										"
									>
										<option value="kg" selected>Kg</option>
										<option value="cesta">Cesta</option>
										<option value="manojo">Manojo</option>
										<option value="unid">Unid</option>
										<option value="saco">Saco</option>
									</select>
								</td>
								<td v-if="pivotUSDBoolean" class="border-2 px-4 py-2">
									{{ precioBsArray[localId[0].precio.length - k] }}
								</td>
								<td v-else class="border-2 px-4 py-2">
									<input
										ref="inputBs"
										class="w-24"
										type="text"
										@change="inputFormat(localId[0].precio.length - k)"
										@keypress.enter="nextRow(k)"
										v-model.lazy="precioBsArray[localId[0].precio.length - k]"
									/>
								</td>
								<td v-if="pivotUSDBoolean" class="border-2 px-4 py-2">
									<input
										ref="inputUSD"
										type="text"
										class="w-12"
										@change="inputFormat(localId[0].precio.length - k)"
										@keypress.enter="nextRow(k)"
										v-model.lazy="precioUsdArray[localId[0].precio.length - k]"
									/>
								</td>
								<td v-else class="border-2 px-4 py-2">
									{{ precioUsdArray[localId[0].precio.length - k] }}
								</td>
								<td class="border-2 px-4 py-2">
									<input
										class="w-10"
										type="text"
										v-model="
											localId[0].precio[localId[0].precio.length - k].iva
										"
									/>
								</td>
								<td class="border-2 px-4 py-2">
									<input
										class="w-10"
										type="text"
										name=""
										id=""
										v-model="
											localId[0].precio[localId[0].precio.length - k].descuento
										"
									/>
								</td>
								<td
									v-if="
										localId[0].precio[localId[0].precio.length - k].updated_at
									"
									class="border-2 px-4 py-2"
								>
									{{
										Fecha(
											localId[0].precio[localId[0].precio.length - k].updated_at
										)
									}}
								</td>
								<td v-else class="border-2 px-4 py-2"></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import vSelect from "vue-select";
import "@/assets/styles/vue-select.css";
import gql from "graphql-tag";
import {
	formato,
	formato4Decimales,
	firstLetterUpperCase,
	cambiarComasPuntos,
	cambiarPuntosComas,
	Fecha,
	intToBoolean
} from "../functions.js";

export default {
	components: {
		vSelect
	},
	data() {
		return {
			mostrarBotonGuardar: true,
			tipoLocalesQuery: [],
			productosTotalSinFiltro: [],
			productoInvetario: [],
			selectRefresh: true,
			filtroRubro: [],
			rubroNuevo: "",
			productoSelect: "",
			precioUsd: 0,
			productos: [],
			mensaje: "",
			iva: 0,
			seleccionUnidad: "",
			showMessage: false,
			localId: {},
			llenarRubro: [],
			llenarProducto: {},
			deleteMessage: false,
			indexRow: 0,
			idBorrar: [],
			llenarDatosPrimeraVez: false,
			tipoCambioConsulta: {},
			mostrarBoton: false,
			mostrarMensaje: false,
			mensajeCarga: "",
			errorQueries: false,
			tasaCambioProveedor: 0,
			tasaCambioProveedorInput: 0,
			pivotUSDBoolean: true,
			precioUsdArray: [],
			precioBsArray: [],
			precioBs: 0,
			precioBsInput: "",
			precioUsdInput: ""
		};
	},
	async created() {
		this.$store.state.isLoading = true;
		await this.tasaCambioQuery();
		await this.tipoLocalesRubro();
		await this.productoInventarioProveedor();
		await this.preciosProveedor();
		this.$store.state.isLoading = false;
	},
	methods: {
		formato,
		formato4Decimales,
		firstLetterUpperCase,
		cambiarComasPuntos,
		Fecha,
		cambiarPuntosComas,
		intToBoolean,
		preciosCero() {
			if (this.pivotUSDBoolean) {
				this.precioUsdArray = this.precioUsdArray.map(() => {
					return "0";
				});
			} else {
				this.precioBsArray = this.precioBsArray.map(() => {
					return "0";
				});
			}
			for (let i = 0; i < this.localId[0].precio.length; i++) {
				this.inputFormat(i);
			}
		},
		async guardarCambiosProducto() {
			await this.guardarTasaProveedor();
			this.$store.state.isLoading = true;
			let runMutation = true;
			let cambioInventario = JSON.parse(JSON.stringify(this.localId[0].precio));
			this.mensaje = "Actualizando inventario proveedor, por favor espere.";
			this.showMessage = true;
			for (let i = 0; i < cambioInventario.length; i++) {
				delete cambioInventario[i].__typename;
				delete cambioInventario[i].producto;

				try {
					if (isNaN(cambioInventario[i].precio_bs)) {
						cambioInventario[i].precio_bs = parseFloat(
							cambiarComasPuntos(String(cambioInventario[i].precio_bs))
						);
					}
					cambioInventario[i].precio_bs = parseFloat(
						cambioInventario[i].precio_bs
					);
					cambioInventario[i].precio_usd = parseFloat(
						cambioInventario[i].precio_bs / this.tasaCambioProveedor
					);
					cambioInventario[i].descuento = parseFloat(
						cambioInventario[i].descuento
					);
					cambioInventario[i].existencia = this.intToBoolean(
						parseInt(cambioInventario[i].existencia)
					);
					cambioInventario[i].iva = parseFloat(cambioInventario[i].iva);
					cambioInventario[i].local_id = parseInt(cambioInventario[i].local_id);
					cambioInventario[i].producto_id = parseInt(
						cambioInventario[i].producto_id
					);
				} catch (error) {
					console.log(error);
					runMutation = false;
					this.$store.state.isLoading = false;
				}
			}

			if (this.idBorrar.length > 0) {
				this.borrarProductosLista();
			}
			this.mostrarBotonGuardar = false;
			this.showMessage = false;
			if (runMutation) {
				await this.$apollo
					.mutate({
						mutation: require("@/graphql/mutations/actualizarInventario.gql"),
						variables: {
							data: cambioInventario
						}
					})
					.then(() => {
						this.$store.state.isLoading = false;
						this.mensaje = "Productos Guardados.";
						this.showMessage = true;
						this.localId = {};
						this.preciosProveedor();
						this.mostrarBotonGuardar = true;
					})
					.catch(error => {
						this.$store.state.isLoading = false;
						this.mensaje = error;
						this.showMessage = true;
						this.mostrarBotonGuardar = true;
					});
			} else {
				this.$store.state.isLoading = false;
				this.mostrarBotonGuardar = true;
				alert(
					"Algunos datos son invalidos, por favor verifique y vuelva a intentar."
				);
			}
		},
		agregarProducto() {
			if (
				this.rubroNuevo === "" ||
				this.productoSelect === "" ||
				this.seleccionUnidad === "" ||
				isNaN(parseFloat(this.precioUsd)) ||
				isNaN(parseFloat(this.iva)) ||
				isNaN(parseFloat(this.localId[0].descuento_general))
			) {
				this.mensaje =
					"Datos no Validos, verifique que ningun campo este vacio y que los numeros ingresados tengan un formato valido.";
				this.showMessage = true;
			} else {
				let verificado = true;
				let productoId = 0;
				this.mensaje = "";
				this.showMessage = false;

				for (let i = 0; i < this.productoInvetario.length; i++) {
					if (
						this.productoInvetario[i].producto ===
						this.productoSelect.toLowerCase()
					) {
						productoId = this.productoInvetario[i].id;
						i = this.productoInvetario.length;
					}
				}

				for (let i = 0; i < this.llenarProducto[this.rubroNuevo].length; i++) {
					if (this.llenarProducto[this.rubroNuevo][i] === this.productoSelect) {
						this.llenarProducto[this.rubroNuevo].splice(i, 1);
						i = this.llenarProducto[this.rubroNuevo].length;
					}
				}

				this.localId[0].precio.push({
					descuento: parseFloat(this.localId[0].descuento_general),
					existencia: 1,
					iva: parseFloat(this.iva),
					unidad: this.seleccionUnidad,
					local_id: parseInt(this.$route.params.id),
					producto_id: parseInt(productoId),
					precio_bs: this.precioBs,
					precio_usd: this.precioUsd,
					producto: {
						rubro: this.rubroNuevo,
						producto: this.productoSelect
					}
				});
				this.productoSelect = "";
				this.selectRefresh = !this.selectRefresh;
				this.precioBsArray.push(this.formato(this.precioBs));
				this.precioUsdArray.push(this.formato(this.precioUsd));
			}
		},
		borrarFilaIndex(index) {
			this.indexRow = index;
		},
		borrarProducto() {
			this.deleteMessage = false;

			this.llenarProducto[
				this.localId[0].precio[this.indexRow].producto.rubro
					.charAt(0)
					.toUpperCase() +
					this.localId[0].precio[this.indexRow].producto.rubro.slice(1)
			].push(
				this.localId[0].precio[this.indexRow].producto.producto
					.charAt(0)
					.toUpperCase() +
					this.localId[0].precio[this.indexRow].producto.producto.slice(1)
			);
			if (this.localId[0].precio[this.indexRow].id) {
				this.idBorrar.push(parseInt(this.localId[0].precio[this.indexRow].id));
			}
			this.localId[0].precio.splice(this.indexRow, 1);
			this.precioUsdArray.splice(this.indexRow, 1);
			this.precioBsArray.splice(this.indexRow, 1);
			this.selectRefresh = !this.selectRefresh;
		},
		async borrarProductosLista() {
			await this.$apollo
				.mutate({
					mutation: gql`
						mutation($id: [ID!]!) {
							borrarProductoInventario(id: $id) {
								__typename
							}
						}
					`,
					variables: {
						id: this.idBorrar
					}
				})
				.then(() => {
					this.mensaje = "Productos Guardados.";
					this.showMessage = true;
				})
				.catch(error => {
					this.mensaje = error;
					this.showMessage = true;
				});
		},
		async tipoLocalesRubro() {
			await this.$apollo
				.query({
					query: gql`
						query tipoLocalesQuery($locales_id: ID!) {
							tipoLocalesQuery(locales_id: $locales_id) {
								rubro
							}
						}
					`,
					variables: {
						locales_id: parseInt(this.$route.params.id)
					}
				})
				.then(data => {
					this.tipoLocalesQuery = data.data.tipoLocalesQuery;
					for (let i = 0; i < this.tipoLocalesQuery.length; i++) {
						this.filtroRubro.push(this.tipoLocalesQuery[i].rubro);
						this.llenarRubro.push(
							this.tipoLocalesQuery[i].rubro.charAt(0).toUpperCase() +
								this.tipoLocalesQuery[i].rubro.slice(1)
						);
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		async productoInventarioProveedor() {
			await this.$apollo
				.query({
					query: gql`
						query productoInvetario($rubro: [String!]) {
							productoInvetario(rubro: $rubro) {
								producto
								id
								rubro
							}
						}
					`,
					variables: {
						rubro: this.filtroRubro
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.productoInvetario = data.data.productoInvetario;
					console.log(this.productoInvetario);
				})
				.catch(error => {
					console.log(error);
					this.mostrarBoton = true;
					this.errorQueries = true;
					this.mensajeCarga = "Error de Conexión Intente Nuevamente";
				});
		},

		async preciosProveedor() {
			await this.$apollo
				.query({
					query: gql`
						query localId($id: ID!) {
							localId(id: $id) {
								descuento_general
								tasa_dia
								pivote_usd
								precio {
									local_id
									descuento
									existencia
									id
									iva
									unidad
									updated_at
									producto_id
									precio_bs
									precio_usd
									producto {
										id
										producto
										rubro
									}
								}
							}
						}
					`,
					variables: {
						id: this.$route.params.id
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					let nuevoProducto = [];
					this.localId = data.data.localId;
					this.pivotUSDBoolean = this.localId[0].pivote_usd;

					if (this.localId[0].tasa_dia > 0) {
						this.tasaCambioProveedor = this.localId[0].tasa_dia;
						this.tasaCambioProveedorInput = this.formato(
							this.localId[0].tasa_dia
						);
					} else {
						this.tasaCambioProveedor = this.tipoCambioConsulta;
						this.tasaCambioProveedorInput = this.formato(
							this.tasaCambioProveedor
						);
					}
					this.localId[0].precio.forEach(element => {
						this.precioBsArray.push(this.formato(element.precio_bs));
					});
					this.localId[0].precio.forEach(element => {
						this.precioUsdArray.push(this.formato(element.precio_usd));
					});
					for (let i = 0; i < this.localId[0].precio.length; i++) {
						this.localId[0].precio[i].precio_bs = this.formato(
							this.localId[0].precio[i].precio_bs
						);
						this.localId[0].precio[i].precio_usd = this.formato4Decimales(
							this.localId[0].precio[i].precio_usd
						);
						for (let j = 0; j < this.productoInvetario.length; j++) {
							if (
								this.localId[0].precio[i].producto.id ===
								this.productoInvetario[j].id
							) {
								this.productoInvetario.splice(j, 1);
							}
						}
					}
					for (let i = 0; i < this.filtroRubro.length; i++) {
						nuevoProducto = [];
						for (let j = 0; j < this.productoInvetario.length; j++) {
							if (
								this.filtroRubro[i].toLowerCase() ===
								this.productoInvetario[j].rubro
							) {
								nuevoProducto.push(
									this.productoInvetario[j].producto.charAt(0).toUpperCase() +
										this.productoInvetario[j].producto.slice(1)
								);
							}
						}
						this.llenarProducto[
							this.filtroRubro[i].charAt(0).toUpperCase() +
								this.filtroRubro[i].slice(1)
						] = nuevoProducto;
					}
					if (!this.errorQueries) {
						this.mostrarMensaje = false;
						this.llenarDatosPrimeraVez = true;
					}
				})
				.catch(error => {
					console.log(error);
					this.mostrarBoton = true;
					this.mensajeCarga = "Error de Conexión Intente Nuevamente";
				});
		},

		async tasaCambioQuery() {
			await this.$apollo
				.query({
					query: gql`
						query tipoCambioConsulta {
							tipoCambioConsulta(
								orderBy: [{ column: "created_at", order: DESC }]
								first: 1
							) {
								data {
									tipo_cambio
								}
							}
						}
					`,
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.tipoCambioConsulta =
						data.data.tipoCambioConsulta.data[0].tipo_cambio;
				})
				.catch(error => {
					console.log(error);
				});
		},
		/*     llenarDataPrimaria() {
          this.mensajeCarga = "Cargando Data...";
          this.errorQueries = false;
          this.mostrarMensaje = true;
          this.mostrarBoton = false;
          this.llenarRubro = [];
          this.funcion4();
          this.funcion1();
          this.funcion2();
        }, */
		actualizarTasa() {
			this.tipoCambioConsulta = 0;
			this.tasaCambioQuery();
		},
		async guardarTasaProveedor() {
			let validNumber = this.cambiarComasPuntos(this.tasaCambioProveedorInput);
			if (isNaN(validNumber)) {
				alert("Valor de tasa no valido");
			} else {
				this.tasaCambioProveedorInput = this.formato(validNumber);
				this.tasaCambioProveedor = validNumber;
				this.localId[0].tasa_dia = validNumber;
				if (this.localId[0].tasa_dia <= 0) {
					this.localId[0].tasa_dia = this.tipoCambioConsulta;
				}
				this.$store.state.isLoading = true;
				this.showMessage = false;
				await this.$apollo
					.mutate({
						mutation: require("@/graphql/mutations/guardarTasaProveedor.gql"),
						variables: {
							id: this.$route.params.id,
							tasa_dia: parseFloat(this.tasaCambioProveedor),
							pivote_usd: this.pivotUSDBoolean
						}
					})
					.then(() => {
						this.mensaje = "Tasa Proveedor Guardada.";
						this.showMessage = true;
						this.$store.state.isLoading = false;
					})
					.catch(error => {
						this.mensaje = error;
						this.showMessage = true;
						this.$store.state.isLoading = false;
					});
			}
		},
		inputFormat(index) {
			if (this.pivotUSDBoolean) {
				if (this.precioUsdArray[index] == "") {
					this.precioUsdArray[index] = 0;
				}
				if (isNaN(this.precioUsdArray[index])) {
					this.localId[0].precio[index].precio_usd = parseFloat(
						this.cambiarComasPuntos(this.precioUsdArray[index])
					);
				} else {
					this.localId[0].precio[index].precio_usd = this.precioUsdArray[index];
				}
				this.localId[0].precio[index].precio_bs =
					this.localId[0].precio[index].precio_usd * this.tasaCambioProveedor;
				this.precioUsdArray[index] = this.formato(
					this.localId[0].precio[index].precio_usd
				);
				this.precioBsArray[index] = this.formato(
					this.localId[0].precio[index].precio_bs
				);
			} else {
				if (this.precioBsArray[index] == "") {
					this.precioBsArray[index] = 0;
				}
				if (isNaN(this.precioBsArray[index])) {
					this.localId[0].precio[index].precio_bs = parseFloat(
						this.cambiarComasPuntos(this.precioBsArray[index])
					);
				} else {
					this.localId[0].precio[index].precio_bs = this.precioBsArray[index];
				}
				this.localId[0].precio[index].precio_usd =
					this.localId[0].precio[index].precio_bs / this.tasaCambioProveedor;
				this.precioUsdArray[index] = this.formato(
					this.localId[0].precio[index].precio_usd
				);
				this.precioBsArray[index] = this.formato(
					this.localId[0].precio[index].precio_bs
				);
			}
		},
		nextRow(index) {
			let newIndex = index;
			if (this.localId[0].precio.length <= index) {
				newIndex = 0;
			}
			if (this.pivotUSDBoolean) {
				this.$refs.inputUSD[newIndex].focus();
			} else {
				this.$refs.inputBs[newIndex].focus();
			}
		},
		cambioPrecioUSD_BS() {
			if (this.pivotUSDBoolean) {
				if (isNaN(this.precioUsdInput)) {
					this.precioUsd = parseFloat(
						this.cambiarComasPuntos(this.precioUsdInput)
					);
				} else {
					this.precioUsd = this.precioUsdInput;
				}
				this.precioBs = this.precioUsd * this.tasaCambioProveedor;
			} else {
				if (isNaN(this.precioBsInput)) {
					this.precioBs = parseFloat(
						this.cambiarComasPuntos(this.precioBsInput)
					);
				} else {
					this.precioBs = this.precioBsInput;
				}

				this.precioUsd = this.precioBs / this.tasaCambioProveedor;
			}
			this.precioUsdInput = this.formato(this.precioUsd);
			this.precioBsInput = this.formato(this.precioBs);
		},
		tasaCambioFormato() {
			this.tasaCambioProveedor = parseFloat(
				this.cambiarComasPuntos(this.tasaCambioProveedorInput)
			);
			this.tasaCambioProveedorInput = this.formato(this.tasaCambioProveedor);

			for (let i = 0; i < this.localId[0].precio.length; i++) {
				this.inputFormat(i);
			}
		}
	}
};
</script>

<style scoped></style>
